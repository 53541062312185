<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-row>
          <b-col cols="12">
            <div
              v-if="customer"
              class="profile-informations"
            >
              <b-row>
                <b-col class="settings-title" cols="10">
                  {{ $t('views.client.details.body.left.subscription-affect.subscription-resume-label') }}
                </b-col>
              </b-row>
              <b-row class="mt-3 mb-3">
                <b-col cols="1">
                  <img
                    v-if="src"
                    :src="src"
                    class="customer-img"
                    height="60"
                    width="60"
                  >
                  <template v-else>
                    <d-avatar
                      :full-name="fullName"
                      size="60"
                      color="#DDDDDD"
                      text-color="#4D4F5C"
                      text-font-size="30"
                    />
                  </template>
                </b-col>
                <b-col class="mt-2">
                  <b-row
                    :class="innerWidth ? 'ml-2' : 'ml-4'"
                  >
                    <b-col class="black-customer-fullname" cols="12">
                      {{ fullName + (customer.phoneNumber ? ' (' + customer.phoneNumber + ')' : '') }}
                    </b-col>
                    <b-col class="gray-text-subtitle" cols="12">
                      {{ email }}
                    </b-col>
                    <b-col cols="6">
                      <div class="mb-2">
                        <add-new-rib
                          text-link="views.client.details.body.left.subscription-affect.add-new-rib"
                          @on:add-link-click="show = !show"
                        />
                        <div v-if="sources.length > 0" class="d-inline-block mt-1">
                          <img :src="sepaIcon"/>
                          <select
                            ref="selectedSepa"
                            class="sepa ml-2 background-light-blue-inputs form-control"
                            size="sm"
                          >
                            <template v-for="(source, i) of sources">
                              <option
                                :value="source.id"
                                :key="'bank-' + i"
                              >
                                <label>
                                  &#149;
                                  &#149;
                                  &#149;
                                  &#149;
                                </label>
                                <label class="sepa-iban-last4">
                                  {{ source.last4 }}
                                </label>
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                    </b-col>
                    <b-col align="right">
                      <rib
                        :client="customer"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col
            v-for="(subs, key) in selectedSubscriptions.values"
            :key="'subscriptionPlan' + key"
            :style="'box-shadow: 0 0 10px 10px rgba(145,145,145,0.2); border-radius: 20px; margin-bottom: 10px; padding: 20px;'"
            cols="12"
          >
            <b-row>
              <b-col cols="8">
                <div
                  class="selected-subscription-informations"
                >
                  <b-row class="mt-2">
                    <b-col class="selected-subscription">
                      {{ subs.name }}
                    </b-col>
                    <b-col class="selected-subscription" align="right">
                      {{
                        parseFloat('' + (selectedRates.values[subs.id].price / 100)).toFixed(2) + $currency + ' / ' + getDueDateGapType(selectedRates.values[subs.id].dueDateGapType, selectedRates.values[subs.id])
                      }}
                    </b-col>

                  </b-row>
                  <b-row v-if="selectedRates.selected">
                    <b-col align="right" cols="1">
                    </b-col>
                    <b-col class="gray-text-subtitle">
                      {{ selectedRates.values[subs.id].label }}
                    </b-col>
                    <b-col class="gray-text-subtitle" align="right">
                      {{
                        parseFloat('' + (selectedRates.values[subs.id].fees / 100)).toFixed(2) + $currency + ' ' +
                        $t('views.client.details.body.left.subscription-affect.fees-per-folder')
                      }}
                    </b-col>
                  </b-row>

                  <b-row v-if="selectedSubscriptions.selected">
                    <b-col class="mt-3 gray-text-description" cols="12">
                      {{ subs.description }}
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col cols="4">
                <label class="settings-title mr-1">
                  {{ $t("views.shop.product-transactions.regulation") }}
                </label>
                <div class="pay-subscription-content">
                  <d-button
                    text="views.client.details.body.left.subscription-affect.checkout-subscription"
                    icon="fa fa-fax"
                    class="d-btn-sm btn d-btn-primary font-text-title btn-full-width mb-2"
                    :class="selectedRates.values[subs.id].fees > 0 ? '' : 'disabled'"
                    @on:button-click="onSubscriptionCheckout(selectedRates.values[subs.id])"
                  />
                  <d-button
                    text="views.client.details.body.left.subscription-affect.checkout-due-date"
                    icon="fa fa-fax"
                    class="d-btn-sm btn d-btn-primary font-text-title btn-full-width"
                    @on:button-click="onSubscriptionPriceCheckout(selectedRates.values[subs.id])"
                  />
                  <d-button
                    text="views.client.details.body.left.subscription-affect.select-due-date"
                    class="d-btn-sm btn d-btn-secondary font-text-title btn-full-width"
                    @on:button-click="changeDefaultSubscriptionCard(subs.id)"
                  />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8">
                <div>
                  <b-row class="mt-3">
                    <b-col cols="10">
                      <label class="settings-title mr-1">
                        {{ $t('views.client.details.body.left.subscription-affect.payment-mode-label') }}
                      </label>
                    </b-col>
                  </b-row>
                  <div
                    v-if="hasPayments"
                    class="d-inline-block mt-3 mb-1 black-customer-fullname"
                  >
                    <vs-radio
                      v-model="paymentsSelections[getKey(subs.id)].method"
                      :vs-value="'stripe_sepa_debit'"
                      :vs-name="getRadioName(subs.id)"
                      class="pointer"
                      color="#0B2772"
                    >
                      {{ $t('views.client.details.body.left.subscription-affect.iban-sepa') }}
                    </vs-radio>
                  </div>

                  <div v-else>
                    <label class="text-danger">
                      {{ $t('views.client.details.body.left.subscription-affect.no-sepa-message') }}
                    </label>
                  </div>

                </div>
                <div>
                  <div
                    class="d-inline-block black-customer-fullname"
                    :class="hasPayments ? 'mt-3': 'mt-2'"
                  >
                    <vs-radio
                      :vs-value="'manual_sepa_debit'"
                      :vs-name="getRadioName(subs.id)"
                      v-model="paymentsSelections[getKey(subs.id)].method"
                      class="pointer"
                      color="#0B2772"
                    >
                      {{ $t('views.client.details.body.left.subscription-affect.other-manual-payments') }}
                    </vs-radio>
                  </div>
                </div>
                <div>
                  <div
                    class="d-inline-block black-customer-fullname"
                    :class="hasPayments ? 'mt-3': 'mt-2'"
                  >
                    <vs-radio
                      v-model="paymentsSelections[getKey(subs.id)].method"
                      :vs-value="'manual'"
                      :vs-name="getRadioName(subs.id)"
                      class="pointer"
                      color="#0B2772"
                    >
                      {{ $t('views.client.details.body.left.subscription-affect.other-payments') }}
                    </vs-radio>
                  </div>
                  <div class="gray-text-description">
                    {{ $t('views.client.details.body.left.subscription-affect.other-payments-description') }}
                  </div>
                </div>
              </b-col>

              <b-col cols="4">
                <b-row
                  class="mt-5"
                >
                  <b-col cols="12" style="padding-top: 25px">
                    <div
                      class="has-calendar"
                      @click="onClick(subs.id)"
                    >
                      <span class="fa fa-calendar form-control-feedback"></span>
                      <b-form-input
                        :value="subscriptionDateFormatText(subs.id)"
                        class="date-pick background-light-blue-inputs"
                        size="sm"
                        readonly
                      />

                      <d-calendar-modal
                        v-model="selectedDate"
                        :identifer="subs.id"
                        :show="showCalendar"
                        :filterFromDate="filterFrom"
                        @on:calendar-change="updateSubscriptionDateFormat"
                      />
                    </div>
                  </b-col>
                  <b-col
                    v-if="paymentsSelections[getKey(subs.id)].method === 'stripe_sepa_debit' || paymentsSelections[getKey(subs.id)].method === 'manual_sepa_debit'"
                    cols="12"
                    class="black-customer-fullname mt-2"
                  >
                    {{ $t('views.client.details.body.left.subscription-affect.first-date-withdrawal') }}
                    {{ parseFloat('' + (selectedRates.values[subs.id].price / 100)) + $currency }}
                  </b-col>
                  <b-col
                    v-if="paymentsSelections[getKey(subs.id)].method === 'manual'"
                    cols="12"
                    class="black-customer-fullname mt-2"
                  >
                    {{ $t('views.client.details.body.left.subscription-affect.first-payment-date') }}
                    {{ parseFloat('' + (selectedRates.values[subs.id].price / 100)).toFixed(2) + $currency }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <due-date-table
          :subscription-card="dueDateSubscriptionCard"
          :client="customer"
        />
      </b-col>
    </b-row>
    <iban-modal
      :IbanFullName="fullName"
      :IbanEmail="email"
      :show="show"
      @on:save="onIbanModalSave"
    />

    <b-row class="mt-4">
      <b-col align="right">
        <d-button
          :class="innerWidth ? '' : ' custom-customer-button mt-4 mb-3'"
          text="general.actions.previous"
          class="d-btn-sm btn d-btn-primary-outline-new font-text-title mr-2"
          @on:button-click="$emit('on:previous')"
        />
        <d-button
          v-if="showConfirm"
          :class="[checkReadyAttribution ? '':'disabled', innerWidth ? '' : ' custom-customer-button mt-4 mb-3']"
          text="general.actions.validate"
          class="d-btn-sm btn d-btn-primary font-text-title"
          @on:button-click="validateSubscriptionAttribution"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Client from "@/classes/doinsport/Client";
import {getClubClient} from "@api/doinsport/services/client/client.api";
import {getCustomerSources, postSource} from "@api/doinsport/services/stripe/customer/source/source.api";
import {fromIdToIriReference} from "@/utils/form";
import {postSubscriptionCard} from "@api/doinsport/services/subscription-card/subscription-card.api";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {SUCCESS} from "@plugins/flash";
import Product from "@/classes/nextore/Product";
import DueDateTable from "@custom/clients/subscriptions-tab/DueDateTable";
import {
  postStripeSubscription,
  postStripeSubscriptionCard
} from "@api/doinsport/services/stripe/subscription/subscription.api";

export default {
  data: () => ({
    disabledConfirm: true,
    showConfirm: true,
    show: false,
    isSaving: false,
    selectedSubscriptionCalendar: null,
    display: false,
    checkoutKey: 0,
    showCalendar: false,
    requiredPaymentSelection: false,
    sourceList: [],
    sepaIcon: require('@/assets/icons/sepa/sepa.svg'),
    customer: null,
    selectedDate: null,
    dueDateSubscriptionCard: {},
    filterFrom: null,
    filterAfter: null,
    dropDownDate: null,
    paymentSelection: [],
    paymentsSelections: [],
    subscriptionDateFormat: [],
    selectedSubscription: null,
    paymentSelectionName: [],
    selectedPaymentMethod: {
      values: [],
      method: null,
      value: 0,
      selected: false,
    },
  }),
  props: {
    selectedSubscriptions: {
      type: Object,
      default: () => ({
        values: [],
        selected: false,
      })
    },
    selectedRates: {
      type: Object,
      default: () => ({
        values: [],
        selected: false,
      })
    }
  },
  components: {
    DueDateTable,
    Rib: () => import('@views/client/details/body/right/rib/Index'),
    IbanModal: () => import("@custom/stripe/IbanModal"),
    AddNewRib: () => import('@custom/subscrtiptions/new/AddItemLink'),
  },
  watch: {
    selectedRate: {
      deep: true,
      handler() {
        if (this.selectedRates.selected) {
          this.initDueDateSubscriptionCard(this.selectedRates.values[this.selectedSubscription.id]);
        }
      }
    }
  },
  computed: {
    club() {
      return this.$store.getters["auth/currentClub"];
    },
    user() {
      return this.$store.getters["auth/currentUser"];
    },
    innerWidth() {
      return this.$store.getters['layout/getInnerWidth'] > 900;
    },
    isEnabled() {
      if (this.selectedSubscription === null) {
        return false;
      }
      if (this.selectedDate === null) {
        return false;
      }
      if (this.paymentSelection === null) {
        return false;
      }
      if ((this.paymentSelection === 'stripe_sepa_debit' || this.paymentSelection === 'manual_sepa_debit') && this.sourceList.length === 0) {
        return false;
      }

      return true;
    },
    sources() {
      return this.sourceList.slice().reverse();
    },
    src() {
      if (this.customer) {
        if (this.customer.identityPhoto) {
          return this.$filePath(this.customer.identityPhoto.contentUrl);
        } else if (this.customer.user) {
          if (this.customer.user.avatar) {
            return this.$filePath(this.customer.user.avatar.contentUrl);
          }
        }

        return null;
      } else {
        return null;
      }
    },
    fullName() {
      if (this.customer) {
        if (this.customer.lastName === null) {
          return this.customer.fullName;
        } else {
          try {
            const indexOfLastName = this.customer.fullName.indexOf(this.customer.lastName);
            const firstName = this.customer.fullName.substring(0, indexOfLastName - 1);
            const lastName = this.customer.fullName.substring(indexOfLastName);

            return firstName.length === 0 ? firstName : firstName + ' ' + lastName.toUpperCase();
          } catch (e) {
            return this.customer.fullName;
          }
        }
      }
    },
    email() {
      return this.customer ? this.customer.email : this.$t('general.actions.undefined');
    },
    checkReadyAttribution() {
      let check = true;
      if (this.paymentsSelections.length === 0) {
        return false;
      }

      this.paymentsSelections.forEach(item => {
        if (item.startDate === null || item.startDate === '' || item.method === null || item.method === '') {
          check = false;
        } else {
          if (item.method === 'stripe_sepa_debit') {
            if (this.sourceList.length === 0) {
              check = false;
            }
          }
        }

        if (item.subsId === this.selectedSubscription.id) {
          this.dueDateSubscriptionCard.paymentMethod = item.method;
        }

      });

      return check;
    },
    showOpenModalBtn() {
      return this.$store.getters["nextore/getProducts"] ? (this.$store.getters["nextore/getProducts"].length > 0) : false;
    },
    hasPayments() {
      return this.$store.getters["auth/currentClub"].stripeAccountReference !== null;
    }
  },
  methods: {
    changeDefaultSubscriptionCard(subsId) {
      this.selectedSubscriptions.values.forEach(item => {
        if (item.id === subsId) {
          this.selectedSubscription = item;
          this.paymentsSelections.forEach(itemSelec => {
            if (itemSelec.subsId === subsId) {
              this.initDueDateSubscriptionCard(this.selectedRates.values[subsId], itemSelec);
            }
          });
        }
      });
    },
    updateSubscriptionDateFormat(newDate) {
      this.showConfirm = false;
      this.paymentsSelections.map(item => {
        if (item.subsId === this.selectedSubscriptionCalendar) {
          item.startDate = this.$moment(newDate).hour(0).minute(0).second(0);
          if (item.subsId === this.dueDateSubscriptionCard.id) {
            this.dueDateSubscriptionCard.firstPaymentDate = item.startDate;
          }
        }
      });


      this.selectedSubscriptionCalendar = null;
      this.showConfirm = true;
    },
    getKey(subsId) {
      let key = null;
      this.paymentsSelections.forEach((item, index) => {
        if (item.subsId === subsId) {
          key = index;
        }
      });

      return key;
    },
    subscriptionDateFormatText(subsId) {
      let textDate = null;
      this.paymentsSelections.forEach(item => {
        if (item.subsId === subsId) {
          textDate = item.startDate ? item.startDate.format('DD/MM/YYYY') : this.$t('validation.required');
        }
      });

      return textDate ? textDate : this.$t('validation.required');
    },
    getRadioName(subsId) {
      let name = '';
      this.paymentsSelections.forEach(item => {
        if (item.subsId === subsId) {
          name = item.radioName;
        }
      });

      return name;
    },
    onClick(subsId) {
      this.selectedSubscriptionCalendar = subsId;
      this.showCalendar = !this.showCalendar
    },
    onHide() {
      this.display = false;
    },
    onSubscriptionPriceCheckout(price) {
      const product = new Product();
      product.id = price.nextoreProductReference;
      product.name = price.label;
      product.price = parseFloat('' + (price.price / 100));
      product.quantity = 1;

      this.$store.dispatch('nextore/setCustomer', this.customer);
      this.$store.dispatch('nextore/addProduct', product);

      this.$nextTick(() => {
        setTimeout(() => {
          this.display = true;
        }, 50)
      });


    },
    openCheckoutModal() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.display = true;
        }, 50)
      });
    },
    onSubscriptionCheckout(price) {
      if (price.fees === 0) {
        return;
      }
      const club = this.$store.getters['auth/currentClub'];
      if (club.nextoreAccount) {
        const product = new Product();
        product.id = club.nextoreAccount.feesProductReference;
        product.name = this.$t('views.client.details.body.left.subscription-affect.fees-rate');
        product.price = parseFloat('' + (price.fees / 100));
        product.quantity = 1;
        this.$store.dispatch('nextore/setCustomer', this.customer);
        this.$store.dispatch('nextore/addProduct', product);

        this.$nextTick(() => {
          setTimeout(() => {
            this.display = true;
          }, 50)
        });
      }
    },
    async validateSubscriptionAttribution() {
      let count = this.paymentsSelections.length;
      if (!this.isSaving) {
        this.isSaving = true;

        for (const item of this.paymentsSelections) {
          count = count - 1;
          await this.sendSubscriptionCard(item, count);
        }
      }

    },
    async sendSubscriptionCard(item, count) {
      let subscriptionCard = {
        plan: "/clubs/subscription-plans/" + item.subsId,
        planPrice: "/clubs/subscription-plans/prices/" + this.selectedRates.values[item.subsId].id,
        ownerClient: "/clubs/clients/" + this.customer.id,
        paymentMethod: item.method,
        firstPaymentDate: item.startDate.format('YYYY-MM-DD'),
        clients: [
          "/clubs/clients/" + this.customer.id
        ]
      }

      if (item.method === 'stripe_sepa_debit') {
        subscriptionCard = {
          stripeSourceReference: this.$refs.selectedSepa.options[this.$refs.selectedSepa.selectedIndex].value,
          subscriptionPlanPrice: subscriptionCard.planPrice,
          paymentMethod: "stripe_sepa_debit",
          client: subscriptionCard.clients[0],
          firstPaymentDate: subscriptionCard.firstPaymentDate,
        }
      }

      await this.getSubscriptionCardApi(item.method, subscriptionCard)
        .then(async (response) => {
            if (count <= 0) {
              this.$flash(null, this.$t('general.actions.success-update'), 3000, SUCCESS);
              await this.$router.push({name: 'customer_details', params: {id: this.$route.params.id}});
            }
          }
        )
        .finally(() => {
          if (count <= 0) {
            this.isSaving = false;
          }
        })
      ;
    },
    onIbanModalSave(data) {
      const source = {
        client: fromIdToIriReference('/clubs/clients', this.customer.id),
        stripeSourceReference: data.source.id
      };

      postSource(source)
        .then(() => {
          this.$flash(null, this.$t('views.client.details.body.left.subscription-affect.iban-validated'), 3000, SUCCESS);

          this.customer.stripeCustomerReference ? this.loadCustomerSources() : this.loadCustomer();
        })
      ;
    },
    getSubscriptionCardApi(method, subscriptionCard) {
      return method === 'stripe_sepa_debit' ? postStripeSubscription(subscriptionCard) : postSubscriptionCard(subscriptionCard)
    },
    onChangedDate(newDate) {
      this.selectedDate = this.$moment(newDate).hour(0).minute(0).second(0);
      this.dueDateSubscriptionCard.firstPaymentDate = this.selectedDate;
    },
    loadCustomerSources() {
      this.sourceList = [];

      getCustomerSources(this.$route.params.id)
        .then((response) => {
          if (isNotUndefinedAndNotNull(response.data.sources)) {
            if (isNotUndefinedAndNotNull(response.data.sources.sepa_debit)) {
              this.sourceList = response.data.sources.sepa_debit;
            }
          }
        })
      ;
    },
    loadCustomer() {
      getClubClient(this.$route.params.id)
        .then((response) => {
          this.customer = new Client(response.data, {serialize: true});
          this.customer.rib = response.data.rib;
          this.loadCustomerSources();
        })
    },
    getDueDateGapType(dueDateGapType, selectedRate) {
      let label = parseInt(selectedRate.dueDateGap) === 1 ? '' : ' ' + selectedRate.dueDateGap + ' ';

      if (dueDateGapType !== 'week') {
        return label + this.$t('general.actions.' + dueDateGapType).toLowerCase();
      } else {
        return label + this.$t('general.actions.week.label').toLowerCase();
      }
    },
    initDueDateSubscriptionCard(price, item = null) {
      const data = {
        id: this.selectedSubscription.id,
        name: this.selectedSubscription.name,
        firstPaymentDate: item ? item.startDate : null,
        paymentMethod: item ? item.method : null,
        planPrice: price
      };

      this.dueDateSubscriptionCard = data;
    }
  },
  created() {
    this.filterFrom = this.$moment.utc().add(-1, 'days');
    this.filterAfter = null; // this.$moment.utc().add(1, 'year');

    this.selectedSubscriptions.values.forEach(item => {
      if (this.selectedSubscription === null) {
        this.selectedSubscription = item;
        this.initDueDateSubscriptionCard(this.selectedRates.values[item.id]);
      }
      this.paymentSelectionName[item.id] = 'radio-' + item.id;
      this.paymentSelection[item.id] = null;
      this.subscriptionDateFormat[item.id] = null;

      this.paymentsSelections.push({
        subsId: item.id,
        radioName: 'radio-' + item.id,
        method: null,
        startDate: null,
      });
    });

    this.loadCustomer();
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/subscriptions/_subscription-resume.scss";
@import "@lazy/_custom-buttom-mobile.scss";

.pay-subscription-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
}
</style>
